const routes = {
  landing: [
    {
      name: "landing",
      path: "/",
      component: () => import("src/layouts/LandingLayout.vue"),
      children: [
        {
          name: "index",
          path: "",
          meta: {
            title: "Top Lawn Care Services Near Me",
            description:
              "Get expert lawn care with Mowandblow.com: mowing, blowing, weed control, edging, and more. Call (601) 808-5296 for top services.",
          },
          component: () => import("src/pages/home/IndexPage.vue"),
        },
        {
          name: "weather",
          path: "weather",
          meta: {
            title: "Local weather for your lawn",
            description:
              "Get expert lawn care with Mowandblow.com: mowing, blowing, weed control, edging, and more. Call (601) 808-5296 for top services.",
          },
          component: () => import("src/pages/home/weather/WeatherIndex.vue"),
        },
        {
          name: "mobile-applications",
          path: "mobile-applications",
          meta: {
            title: "Mobile applications for your lawn",
            description: "Try our mobile applications for the best experience.",
          },
          component: () => import("src/pages/home/home/MobileApps.vue"),
        },
        /*{
          name: "state-weather",
          path: "weather/:state",
          meta: {
            title: "Local weather for your state",
          },
          component: () => import("src/pages/home/weather/StateWeather.vue"),
        },*/
        {
          name: "city-weather",
          path: "weather/:state/:city",
          meta: {},
          component: () => import("src/pages/home/weather/CityWeather.vue"),
        },
        {
          name: "providers map",
          path: "providers-map",
          component: () => import("src/pages/home/community/ProvidersMap.vue"),
        },

        {
          name: "pricing",
          path: "pricing",
          meta: {
            title: "Pricing & Plans for our Service",
            description:
              "Our plans & pricing make it simple for service providers to use our platform.  Customers never pay any service fees.",
          },
          component: () => import("src/pages/home/home/PricingPage2.vue"),
        },
        {
          name: "process",
          path: "how-it-works",
          meta: {
            title: "How our Service Process Works",
            description:
              "Our straightforward process makes it simple to obtain quotes and start services with qualified professionals via our application.",
          },
          component: () => import("src/pages/home/home/ProcessPage.vue"),
        },
        {
          name: "platform",
          path: "platform",
          meta: {
            title: "Mow & Blow Platform",
            description:
              "Our platform is designed to make your business succeed and thrive in today's environment. Control nearly your entire business from within our app.",
          },
          component: () => import("src/pages/home/PlatformPage.vue"),
        },
        {
          name: "marketplace",
          path: "marketplace",
          meta: {
            title: "Mow & Blow Marketplace",
            description:
              "Our top notch marketplace makes it so customers can get the best service experience possible. Review bids and decide after considering your options.",
          },
          component: () => import("src/pages/home/MarketplacePage.vue"),
        },
        {
          name: "about",
          path: "about",
          meta: {
            title:
              "Lawn Care and Landscaping Marketplace - About Mow &amp; Blow",
            description:
              "General information on the history and founding of mowandblow.com. Also discusses the key features and value propositions for our application.",
          },
          component: () => import("src/pages/home/home/AboutPage.vue"),
        },
        {
          name: "mission",
          path: "mission",
          meta: {
            title: "Mission of Mow &amp; Blow",
            description:
              "Our mission is simple - push button lawn care experience. We strive to create a super app for lawn service customers and professionals.",
          },
          component: () => import("src/pages/home/MissionPage.vue"),
        },
        {
          name: "communication",
          path: "communication",
          meta: {
            title: "Communication with Mow &amp; Blow",
            description:
              "The communication functionality in our application is second to none.",
          },
          component: () => import("src/pages/home/home/CommunicationPage.vue"),
        },
        {
          name: "billing",
          path: "billing",
          meta: {
            title:
              "Mow & Blow Billing: Flexible Payment Options - Pay-As-You-Go or Monthly",
            description:
              "Explore hassle-free billing with Mow & Blow: Choose Pay-As-You-Go for flexibility or Monthly Billing for consistent lawn care.",
          },
          component: () => import("src/pages/home/home/BillingPage.vue"),
        },
        {
          name: "scheduling",
          path: "scheduling",
          meta: {
            title:
              "Easy Lawn Care Scheduling | Book Your Service Online | Mow & Blow",
            description:
              "Book lawn care on your schedule with Mow & Blow. Quick setup, personalization, and automatic reminders for a hassle-free experience.",
          },
          component: () => import("src/pages/home/home/SchedulingPage.vue"),
        },
        {
          name: "service-providers",
          path: "service-providers",
          meta: {
            title: "Service Providers",
            description:
              "Service provider registration and search. View our existing service providers and access pages decidated to each of their companies.",
          },
          component: () =>
            import("src/pages/home/community/ServiceProviders.vue"),
        },
        {
          name: "service-provider",
          path: "service-providers/:id",
          component: () =>
            import("src/pages/home/community/ServiceProvider.vue"),
        },
        {
          name: "service-areas",
          path: "service-areas",
          meta: {
            title:
              "Lawn Care and Landscaping Marketplace - Mow &amp; Blow Service Areas",
            description: "Information on our areas of service.",
          },
          component: () => import("src/pages/home/ServiceArea.vue"),
        },
        {
          name: "calculators",
          path: "calculators",
          meta: {
            title:
              "Project Estimation Calculators for Lawn Service Customers and Pros",
            description: "List and description of the services we provide.",
          },
          component: () =>
            import("src/pages/home/calculators/CalculatorIndex.vue"),
        },
        {
          name: "services",
          path: "services",
          meta: {
            title:
              "Lawn Care and Landscaping Marketplace - Mow &amp; Blow Services",
            description: "List and description of the services we provide.",
          },
          component: () => import("src/pages/home/services/ServicesPage2.vue"),
        },
        {
          name: "service",
          path: "services/:id",
          component: () => import("src/pages/home/services/ServicePage.vue"),
        },
        {
          name: "help",
          path: "help",
          meta: {
            title:
              "Help Center - Answers to Frequently Asked Questions and more",
            description:
              "Answers to Frequently Asked Questions about lawn care, lawn mowing, shrub trimming, safety, fertilization, and more.",
          },
          component: () => import("src/pages/home/help/HelpIndex.vue"),
        },
        {
          name: "faqs",
          path: "faqs",
          meta: {
            title:
              "Help Center - Answers to Frequently Asked Questions and more",
            description:
              "Answers to Frequently Asked Questions about lawn care, lawn mowing, shrub trimming, safety, fertilization, and more.",
          },
          component: () => import("src/pages/home/help/HelpIndex.vue"),
        },
        {
          name: "residential",
          path: "residential-services",
          meta: {
            title: "Residential Services Available  - Mow & Blow",
            description:
              "List and description of the residential services we provide.",
          },
          component: () => import("src/pages/home/home/ResidentialPage2.vue"),
        },
        {
          name: "residential-service",
          path: "residential-services/:id",
          component: () => import("src/pages/home/services/ServicePage.vue"),
        },
        {
          name: "commercial",
          path: "commercial-services",
          meta: {
            title: "Commercial Services Available  - Mow & Blow",
            description:
              "List and description of the commercial services we provide.",
          },
          component: () => import("src/pages/home/home/CommercialPage2.vue"),
        },
        {
          name: "faq",
          path: "faqs/:id",
          component: () => import("src/pages/home/help/FaqPage.vue"),
        },
        {
          name: "commercial-service",
          path: "commercial-services/:id",
          component: () =>
            import("src/pages/accounts/customer/portal/ServicePage.vue"),
        },
        {
          name: "local-service",
          path: "local/:location/:service",
          meta: {
            title:
              "Lawn Care and Landscaping Marketplace - Mow &amp; Blow Service",
          },
          component: () => import("src/pages/home/community/LocalService.vue"),
        },
        {
          name: "locations",
          path: "locations",
          meta: {
            title: "Service Area Overview  - States We Serve",
          },
          component: () => import("src/pages/home/locations/USLocations.vue"),
        },
        {
          name: "state",
          path: "locations/:state",
          meta: {
            title: "Service Area Overview  - States We Serve",
          },
          component: () =>
            import("src/pages/home/locations/StateLocations.vue"),
        },
        {
          name: "city",
          path: "locations/:state/:city",
          meta: {},
          component: () => import("src/pages/home/locations/CityLocations.vue"),
        },
        /* {
          name: "local-keyword",
          path: "community/:location/:keyword",
          meta: {
            title: "Lawn Care and Landscaping Marketplace - Mow &amp; Blow",
          },
          component: () =>
            import("src/pages/home/community/LocalKeyword.vue"),
        },*/
        {
          name: "careers",
          path: "careers",
          meta: {
            title:
              "Lawn Care and Landscaping Marketplace - Careers at Mow &amp; Blow",
            description: "Register for a new career with mowandblow.com",
          },
          component: () =>
            import("src/pages/home/contractor/ContractorPage.vue"),
        },
        {
          name: "contractors",
          path: "pros",
          meta: {
            title:
              "Lawn Care and Landscaping Marketplace - Mow &amp; Blow Professionals",
          },
          component: () => import("src/pages/home/careers/CareersPage.vue"),
        },
        {
          name: "careers",
          path: "careers",
          meta: {
            title: "Join Our Team - Careers at Mow & Blow",
          },
          component: () => import("src/pages/home/careers/CareersPage.vue"),
        },
        {
          name: "privacy",
          path: "privacy",
          meta: {
            title: "Privacy Policy - Mow & Blow ",
            description:
              "Privacy policy for mowandblow.com. This privacy policy must be consented to in order to use this website.",
          },
          component: () => import("src/pages/legal/PrivacyPolicy.vue"),
        },
        {
          name: "terms",
          path: "terms",
          meta: {
            title: "Terms of Use - Mow & Blow",
            description:
              "Terms of use for mowandblow.com. These terms of use must be consented to in order to use this website.",
          },
          component: () => import("src/pages/legal/TermsAndConditions.vue"),
        },
        {
          name: "copyright",
          path: "copyright",
          meta: {
            title:
              "Lawn Care and Landscaping Marketplace - Mow &amp; Blow Copyright",
            description: "Copyright information for mowandblow.com",
          },
          component: () => import("src/pages/legal/CopyrightPolicy.vue"),
        },
        {
          name: "policies",
          path: "policies",
          meta: {
            title: "Company Policies - Mow &amp; Blow",
            description:
              "List of company policies. Company policies for customers and service providers that must be consented to to use our application.",
          },
          component: () => import("src/pages/legal/CompanyPolicies.vue"),
        },
      ],
    },
    {
      name: "basic",
      path: "/",
      component: () => import("src/layouts/BasicLandingLayout.vue"),
      children: [
        {
          name: "reset-password",
          path: "/password-reset/confirm/:uid/:token",
          component: () =>
            import("src/pages/accounts/common/auth/PasswordResetPage.vue"),
        },
      ],
    },
    {
      name: "learn-index",
      path: "/learn",
      meta: {
        title:
          "Learn all about Your Lawn and How to Maintain It Overview of Common Types of Grass",
        description:
          "Read the articles and blog posts that Mow & Blow has created. These articles will teach you about lawn care techniques, products, and servicing expectations.",
      },
      component: () => import("src/pages/home/articles/LearnIndex.vue"),
    },
    {
      name: "grass",
      path: "/grass",
      component: () => import("src/layouts/ArticleLayout.vue"),
      children: [
        {
          name: "grass-index",
          path: "",
          meta: {
            title: "Overview of Common Types of Grass",
            description:
              "Read the articles and blog posts that Mow & Blow has created. These articles will teach you about lawn care techniques, products, and servicing expectations.",
          },
          component: () =>
            import("src/pages/home/articles/grass/GrassIndex.vue"),
        },
        {
          name: "centipede",
          path: "centipede-grass",
          meta: {
            title:
              "Centipede Grass - Info on Climate, Use, & Nutritional Needs",
            description:
              "Learn the basics of how to fertilize your lawn and plants. Article discusses the types of fertilizers and the key elements that make up fertilizer.",
          },
          component: () =>
            import("src/pages/home/articles/grass/CentipedeGrass.vue"),
        },
        {
          name: "bermuda",
          path: "bermuda-grass",
          meta: {
            title: "Bermuda Grass - Info on Climate, Use, & Nutritional Needs",
            description:
              "Learn the basics of how to trim and prune your plants. Article discusses the various methods and situations where you should trim your plants.",
          },
          component: () =>
            import("src/pages/home/articles/grass/BermudaGrass.vue"),
        },
        {
          name: "st-augustine",
          path: "st-augustine-grass",
          meta: {
            title:
              "St. Augustine Grass - Info on Climate, Use, & Nutritional Needs",
            description:
              "Learn the basics of how to trim and prune your plants. Article discusses the various methods and situations where you should trim your plants.",
          },
          component: () =>
            import("src/pages/home/articles/grass/StAugustineGrass.vue"),
        },
        {
          name: "zoysia",
          path: "zoysia-grass",
          meta: {
            title: "Zoysia Grass - Info on Climate, Use, and Nutritional Needs",
            description:
              "Learn the basics of how to trim and prune your plants. Article discusses the various methods and situations where you should trim your plants.",
          },
          component: () =>
            import("src/pages/home/articles/grass/ZoysiaGrass.vue"),
        },
        {
          name: "fescue",
          path: "fescue-grass",
          meta: {
            title: "Fescue Grass - Info on Climate, Use, & Nutritional Needs",
            description:
              "Learn the basics of how to trim and prune your plants. Article discusses the various methods and situations where you should trim your plants.",
          },
          component: () =>
            import("src/pages/home/articles/grass/FescueGrass.vue"),
        },
        {
          name: "kentucky-bluegrass",
          path: "kentucky-bluegrass",
          meta: {
            title:
              "Kentucky Bluegrass - Info on Climate, Use, & Nutritional Needs",
            description:
              "Learn the basics of how to trim and prune your plants. Article discusses the various methods and situations where you should trim your plants.",
          },
          component: () =>
            import("src/pages/home/articles/grass/KentuckyBluegrass.vue"),
        },
        {
          name: "ryegrass",
          path: "ryegrass",
          meta: {
            title: "Ryegrass - Info on Climate, Use, & Nutritional Needs",
            description:
              "Learn the basics of how to trim and prune your plants. Article discusses the various methods and situations where you should trim your plants.",
          },
          component: () => import("src/pages/home/articles/grass/RyeGrass.vue"),
        },
      ],
    },
    {
      name: "mowers",
      path: "/mowers",
      component: () => import("src/layouts/ArticleLayout.vue"),
      children: [
        {
          name: "mowers-index",
          path: "",
          meta: {
            title: "Kinds of Lawn Mowers",
            description:
              "Read the articles and blog posts that Mow & Blow has created. These articles will teach you about lawn care techniques, products, and servicing expectations.",
          },
          component: () =>
            import("src/pages/home/articles/mowers/MowersIndex.vue"),
        },
        {
          name: "push-mower",
          path: "push-mower",
          meta: {
            title: "Push Mowers",
            description:
              "Learn the basics of how to fertilize your lawn and plants. Article discusses the types of fertilizers and the key elements that make up fertilizer.",
          },
          component: () =>
            import("src/pages/home/articles/mowers/PushMower.vue"),
        },
        {
          name: "self-propelled-mower",
          path: "self-propelled-mower",
          meta: {
            title: "Self-Propelled Mowers",
            description:
              "Learn the basics of how to fertilize your lawn and plants. Article discusses the types of fertilizers and the key elements that make up fertilizer.",
          },
          component: () =>
            import("src/pages/home/articles/mowers/SelfPropelledMower.vue"),
        },
        {
          name: "riding-mower",
          path: "riding-mower",
          meta: {
            title: "Riding Mowers",
            description:
              "Learn the basics of how to fertilize your lawn and plants. Article discusses the types of fertilizers and the key elements that make up fertilizer.",
          },
          component: () =>
            import("src/pages/home/articles/mowers/RidingMower.vue"),
        },
        {
          name: "zero-turn-mower",
          path: "zero-turn-mower",
          meta: {
            title: "Zero-Turn Mowers",
            description:
              "Learn the basics of how to fertilize your lawn and plants. Article discusses the types of fertilizers and the key elements that make up fertilizer.",
          },
          component: () =>
            import("src/pages/home/articles/mowers/ZeroTurnMower.vue"),
        },
        {
          name: "stand-on-mower",
          path: "stand-on-mower",
          meta: {
            title: "Stand-on Mowers",
            description:
              "Learn the basics of how to fertilize your lawn and plants. Article discusses the types of fertilizers and the key elements that make up fertilizer.",
          },
          component: () =>
            import("src/pages/home/articles/mowers/StandOnMower.vue"),
        },
      ],
    },
    {
      name: "articles",
      path: "/articles",
      component: () => import("src/layouts/ArticleLayout.vue"),
      children: [
        {
          name: "article-index",
          path: "",
          meta: {
            title: "Articles - Mow & Blow",
            description:
              "Read the articles and blog posts that Mow & Blow has created. These articles will teach you about lawn care techniques, products, and servicing expectations.",
          },
          component: () => import("src/pages/home/articles/ArticleIndex.vue"),
        },
        {
          name: "fertilization-101",
          path: "fertilization-101",
          meta: {
            title: "Fertilization 101: How to Fertilize Your Lawn and Plants",
            description:
              "Learn the basics of how to fertilize your lawn and plants. Article discusses the types of fertilizers and the key elements that make up fertilizer.",
          },
          component: () =>
            import("src/pages/home/articles/Fertilization101Page.vue"),
        },
        {
          name: "shrub-trimming-101",
          path: "shrub-trimming-101",
          meta: {
            title: "Shrub Trimming 101: How to Trim & Prune Shrubs",
            description:
              "Learn the basics of how to trim and prune your plants. Article discusses the various methods and situations where you should trim your plants.",
          },
          component: () =>
            import("src/pages/home/articles/ShrubTrimming101.vue"),
        },
        {
          name: "lawn-mowing-101",
          path: "lawn-mowing-101",
          meta: {
            title:
              "Lawn Mowing 101: A Beginner's Guide to Perfecting Your Lawn",
            description:
              "Learn the basics of how to mow and maintain your lawn. Article discusses how to understand the needs of your lawn and maintain it.",
          },
          component: () => import("src/pages/home/articles/LawnMowing101.vue"),
        },
      ],
    },

    {
      name: "catchAll",
      path: "/:catchAll(.*)*",
      component: () => import("pages/accounts/common/auth/ErrorNotFound.vue"),
    },
  ],
  customer: [
    {
      name: "customer",
      path: "/",
      component: () => import("src/layouts/CustomerLayout.vue"),
      children: [
        {
          name: "index",
          path: "",
          component: () =>
            import("src/pages/accounts/customer/common/CustomerIndex.vue"),
        },
        {
          name: "disclosures",
          path: "disclosures",
          component: () =>
            import("src/pages/accounts/customer/portal/DisclosuresPage.vue"),
        },
        {
          name: "change-password",
          path: "/change-password",
          component: () =>
            import("src/pages/accounts/common/auth/ChangePassword.vue"),
        },
        {
          name: "payment-methods",
          path: "payment-methods",
          component: () =>
            import("src/pages/accounts/customer/portal/BillingPage.vue"),
        },
        {
          name: "invoices",
          path: "invoices",
          component: () =>
            import("src/pages/accounts/customer/portal/InvoicesPage.vue"),
        },
        {
          name: "requests",
          path: "requests",
          component: () =>
            import("src/pages/accounts/common/requests/RequestsList.vue"),
        },
        {
          name: "request",
          path: "requests/:id",
          component: () =>
            import("src/pages/accounts/common/requests/RequestActionPage.vue"),
        },
        {
          name: "application",
          path: "application",
          component: () =>
            import(
              "src/pages/accounts/customer/registration/CustomerRegistration.vue"
            ),
        },
        {
          name: "new-user",
          path: "new-user",
          component: () =>
            import("src/pages/accounts/customer/registration/NewUserSetup.vue"),
        },

        {
          name: "billing",
          path: "billing",
          component: () =>
            import("src/pages/accounts/customer/portal/BillingPage.vue"),
        },
        {
          name: "accounts",
          path: "accounts",
          component: () => import("src/components/portal/Accounts.vue"),
        },
        {
          name: "properties",
          path: "properties",
          component: () => import("src/components/portal/Properties.vue"),
        },
        {
          name: "contacts",
          path: "contacts",
          component: () => import("src/components/portal/Contacts.vue"),
        },
        {
          name: "subscriptions",
          path: "subscriptions",
          component: () => import("src/components/portal/Subscriptions.vue"),
        },
        {
          name: "offers",
          path: "offers",
          component: () =>
            import("src/pages/accounts/customer/portal/quotes/OffersPage.vue"),
        },
        {
          name: "offer",
          path: "offers/:id",
          component: () =>
            import("src/pages/accounts/customer/portal/quotes/OfferPage.vue"),
        },
        {
          name: "services",
          path: "services",
          component: () =>
            import("src/pages/accounts/customer/portal/ServicesPage.vue"),
        },
        {
          name: "service",
          path: "services/:id",
          component: () =>
            import("src/pages/accounts/customer/portal/ServicePage.vue"),
        },
        {
          name: "service-records",
          path: "service-records",
          component: () =>
            import(
              "src/pages/accounts/customer/portal/servicerecords/ServiceRecords2.vue"
            ),
        },
        {
          name: "service-record",
          path: "service-records/:id",
          component: () =>
            import(
              "src/pages/accounts/customer/portal/servicerecords/CustomerReview.vue"
            ),
        },
        {
          name: "quote",
          path: "quotes/:id",
          component: () => import("src/components/portal/Quote2.vue"),
        },

        {
          name: "records",
          path: "records",
          component: () => import("src/components/portal/Records.vue"),
        },
        {
          name: "record",
          path: "records/:id",
          component: () => import("src/components/portal/ServiceRecord.vue"),
        },
        {
          name: "messages",
          path: "messages",
          component: () =>
            import("src/pages/accounts/customer/portal/MessagesPage.vue"),
        },
        {
          name: "rewards",
          path: "rewards",
          component: () => import("src/components/portal/Rewards.vue"),
        },
        {
          name: "transactions",
          path: "transactions",
          component: () => import("src/components/portal/Transactions.vue"),
        },
        {
          name: "user",
          path: "user",
          component: () => import("src/components/portal/User.vue"),
        },
        {
          name: "security",
          path: "security",
          component: () => import("src/components/portal/Security.vue"),
        },
        {
          name: "terms",
          path: "terms",
          component: () => import("src/pages/legal/TermsAndConditions.vue"),
        },
        {
          name: "privacy",
          path: "privacy",
          component: () => import("src/pages/legal/PrivacyPolicy.vue"),
        },
        {
          name: "profile",
          path: "/profile",
          component: () =>
            import("src/pages/accounts/professional/crew/CrewProfile.vue"),
        },
        {
          name: "preferences",
          path: "/preferences",
          component: () =>
            import("src/pages/accounts/customer/portal/UserPreferences.vue"),
        },
      ],
    },
    {
      name: "catchAll",
      path: "/:catchAll(.*)*",
      component: () => import("pages/accounts/common/auth/ErrorNotFound.vue"),
    },
  ],
  manager: [
    {
      name: "manager",
      path: "/",
      component: () => import("src/layouts/ManagerLayout.vue"),
      children: [
        {
          name: "mgr-requests",
          path: "requests",
          component: () =>
            import(
              "src/pages/accounts/professional/manager/requests/RequestsList.vue"
            ),
        },
        {
          name: "mgr-requests2",
          path: "requests2",
          component: () =>
            import(
              "src/pages/accounts/professional/manager/requests/RequestsList2.vue"
            ),
        },
        {
          name: "callbacks",
          path: "callbacks",
          component: () =>
            import(
              "src/pages/accounts/professional/manager/requests/CallbacksPage.vue"
            ),
        },
        {
          name: "applications-new",
          path: "applications-new",
          component: () =>
            import("src/pages/accounts/common/node/NodeApplicationsNew.vue"),
        },
        {
          name: "user-registration",
          path: "user-registration",
          component: () => import("src/pages/docs/UserRegistration.vue"),
        },
        {
          name: "provider-outreach",
          path: "provider-outreach",
          component: () =>
            import(
              "src/pages/accounts/professional/manager/sales/ProviderOutreach.vue"
            ),
        },
        {
          name: "customer-outreach",
          path: "customer-outreach",
          component: () =>
            import(
              "src/pages/accounts/professional/manager/sales/CustomerOutreach.vue"
            ),
        },
        {
          name: "local-keyword",
          path: "community/:location/:keyword",
          component: () => import("src/pages/home/community/LocalKeyword.vue"),
        },

        {
          name: "depots",
          path: "depots",
          component: () =>
            import("src/pages/accounts/common/node/NodeDepots.vue"),
        },
        {
          name: "depot",
          path: "depots/:id",
          component: () =>
            import("src/pages/accounts/common/node/NodePage.vue"),
        },
        {
          name: "inspections",
          path: "inspections/:id",
          component: () =>
            import("src/pages/accounts/common/node/NodePage.vue"),
        },

        {
          name: "competitors",
          path: "competitors",
          component: () =>
            import(
              "src/pages/accounts/professional/manager/CompetitorsPage2.vue"
            ),
        },
        {
          name: "competitors2",
          path: "competitors2",
          component: () =>
            import("src/pages/accounts/professional/manager/PlacesPage.vue"),
        },
        {
          name: "payments",
          path: "payments",
          component: () =>
            import("src/pages/accounts/common/node/specific/NodePayment.vue"),
        },
        {
          name: "conversations",
          path: "conversations",
          component: () =>
            import(
              "src/pages/accounts/common/conversations/ConversationsPage.vue"
            ),
        },
        {
          name: "conversation",
          path: "conversations/:id",
          component: () =>
            import(
              "src/pages/accounts/common/conversations/ConversationPage.vue"
            ),
        },

        {
          name: "chat",
          path: "chat/:id",
          component: () =>
            import(
              "src/pages/accounts/common/conversations/ConversationsPageSpecific.vue"
            ),
        },
        {
          name: "messages2",
          path: "messages2",
          component: () =>
            import("src/pages/accounts/customer/portal/MessagesPage.vue"),
        },
        {
          name: "organizer",
          path: "/organizer",
          component: () =>
            import("src/pages/accounts/professional/manager/OrganizerPage.vue"),
        },
        {
          name: "search",
          path: "/search",
          component: () =>
            import("src/pages/accounts/common/search/SearchPage.vue"),
        },
        {
          name: "contacts",
          path: "contacts",
          component: () =>
            import("src/pages/accounts/common/contacts/ContactsPage.vue"),
        },
        {
          name: "xdsf",
          path: "contacts/:id",
          component: () =>
            import("src/pages/accounts/common/contacts/ContactPage.vue"),
        },
        {
          name: "invoices",
          path: "invoices",
          component: () =>
            import("src/pages/accounts/common/node/specific/NodeInvoice.vue"),
        },
        {
          name: "message",
          path: "messages/:id",
          component: () =>
            import("src/pages/accounts/common/messages/MessagePage.vue"),
        },
        {
          name: "messages",
          path: "messages",
          component: () =>
            import("src/pages/accounts/common/messages/MessagesPage.vue"),
        },
        {
          name: "location",
          path: "locations/:id",
          component: () =>
            import("src/pages/accounts/common/locations/LocationPage.vue"),
        },
        {
          name: "locations",
          path: "locations",
          component: () =>
            import("src/pages/accounts/common/locations/LocationsPage.vue"),
        },
        {
          name: "payment",
          path: "payments/:id",
          component: () =>
            import(
              "src/pages/accounts/common/financial/payments/PaymentPage.vue"
            ),
        },
        /*{
          name: "payments",
          path: "payments",
          component: () => import("src/pages/payments/PaymentsPage.vue"),
        },*/
        {
          name: "transaction",
          path: "transactions/:id",
          component: () =>
            import(
              "src/pages/accounts/common/financial/transactions/TransactionPage.vue"
            ),
        },
        {
          name: "transactions",
          path: "transactions",
          component: () =>
            import(
              "src/pages/accounts/common/financial/transactions/TransactionsPage.vue"
            ),
        },
        {
          name: "routable",
          path: "routables/:id",
          component: () =>
            import("src/pages/accounts/common/routables/RoutablePage.vue"),
        },
        {
          name: "routables",
          path: "routables",
          component: () =>
            import("src/pages/accounts/common/routables/RoutablesPage.vue"),
        },
        {
          name: "jobject",
          path: "jobjects/:id",
          component: () =>
            import("src/pages/accounts/common/jobjects/JobjectPage.vue"),
        },
        {
          name: "jobjects",
          path: "jobjects",
          component: () =>
            import("src/pages/accounts/common/jobjects/JobjectsPage.vue"),
        },
        {
          name: "file",
          path: "files/:id",
          component: () =>
            import("src/pages/accounts/common/files/FilePage.vue"),
        },
        {
          name: "files",
          path: "files",
          component: () =>
            import("src/pages/accounts/common/files/FilesPage.vue"),
        },
        {
          name: "tag",
          path: "tags/:id",
          component: () => import("src/pages/accounts/common/tags/TagPage.vue"),
        },
        {
          name: "tags",
          path: "tags",
          component: () =>
            import("src/pages/accounts/common/tags/TagsPage.vue"),
        },
        {
          name: "timeclock",
          path: "timeclocks/:id",
          component: () =>
            import("src/pages/accounts/common/timeclocks/TimeclockPage.vue"),
        },
        {
          name: "timeclocks",
          path: "timeclocks",
          component: () =>
            import("src/pages/accounts/common/timeclocks/TimeclocksPage.vue"),
        },
        {
          name: "user",
          path: "users/:id",
          component: () =>
            import("src/pages/accounts/common/users/UserPage.vue"),
        },
        {
          name: "users",
          path: "users",
          component: () =>
            import("src/pages/accounts/common/users/UsersPage.vue"),
        },
        {
          name: "group",
          path: "groups/:id",
          component: () =>
            import("src/pages/accounts/common/groups/GroupPage.vue"),
        },
        {
          name: "groups",
          path: "groups",
          component: () =>
            import("src/pages/accounts/common/groups/GroupsPage.vue"),
        },
        {
          name: "nodes",
          path: "/nodes",
          component: () =>
            import("src/pages/accounts/common/nodes/NodesPage.vue"),
        },
        {
          name: "node",
          path: "/nodes/:id",
          component: () =>
            import("src/pages/accounts/common/node/NodeBody.vue"),
        },
        {
          name: "node-subpage",
          path: "/nodes/:id/:subpage",
          component: () =>
            import("src/pages/accounts/common/node/NodeBody.vue"),
        },
        {
          name: "property-search",
          path: "/property-search",
          component: () => import("src/components/node/PropertySelector.vue"),
        },
        {
          name: "change-password",
          path: "/change-password",
          component: () =>
            import("src/pages/accounts/common/auth/ChangePassword.vue"),
        },
        {
          name: "profile",
          path: "/profile",
          component: () =>
            import("src/pages/accounts/professional/crew/CrewProfile.vue"),
        },
        {
          name: "mytimeclock",
          path: "my-timeclock",
          component: () =>
            import("src/pages/accounts/common/timeclock/MyTimeClock.vue"),
        },
        {
          name: "manage-tags",
          path: "manage-tags",
          component: () => import("src/pages/accounts/common/ManageTags.vue"),
        },
        {
          name: "weather",
          path: "weather",
          component: () =>
            import("src/pages/home/weather/weather/WeatherIndex.vue"),
        },
        {
          name: "requests",
          path: "requests",
          component: () =>
            import("src/pages/accounts/common/requests/RequestsView.vue"),
        },
        {
          name: "tag",
          path: "tag",
          component: () =>
            import("src/pages/accounts/professional/manager/Tags.vue"),
        },

        {
          name: "processes",
          path: "processes",
          component: () =>
            import("src/pages/accounts/professional/manager/ProcessesPage.vue"),
        },
        {
          name: "pro-applications",
          path: "pro-applications",
          component: () =>
            import("src/pages/accounts/common/node/NodeProApplications.vue"),
        },
        {
          name: "pro-application",
          path: "pro-applications/:id",
          component: () =>
            import("src/pages/accounts/common/node/NodePage.vue"),
        },
        {
          name: "customer-applications",
          path: "applications",
          component: () =>
            import("src/pages/accounts/common/node/NodeApplications.vue"),
        },

        {
          name: "customer-application",
          path: "applications/:id",
          component: () =>
            import("src/pages/accounts/common/node/NodePage.vue"),
        },
        {
          name: "accounts",
          path: "accounts",
          component: () =>
            import("src/pages/accounts/common/node/NodeAccounts.vue"),
        },
        {
          name: "customer-properties",
          path: "properties",
          component: () =>
            import("src/pages/accounts/common/node/NodeProperties.vue"),
        },
        {
          name: "account",
          path: "accounts/:id",
          component: () =>
            import("src/pages/accounts/common/node/NodePage.vue"),
        },
        {
          name: "customer-onboarding",
          path: "customer-onboarding",
          component: () =>
            import(
              "src/pages/accounts/professional/manager/CustomerOnboarding.vue"
            ),
        },
        {
          name: "pros",
          path: "pros",
          component: () =>
            import("src/pages/accounts/professional/manager/Pros.vue"),
        },
        {
          name: "communications",
          path: "communications",
          component: () =>
            import("src/pages/accounts/common/Communications.vue"),
        },
        {
          name: "chat",
          path: "chat",
          component: () =>
            import("src/pages/accounts/professional/manager/ChatPage.vue"),
        },
        {
          name: "property",
          path: "properties/:id",
          component: () =>
            import("src/pages/accounts/common/node/NodePage.vue"),
        },
        {
          name: "services",
          path: "services",
          component: () =>
            import("src/pages/accounts/common/node/specific/NodeService.vue"),
        },

        {
          name: "service",
          path: "services/:id",
          component: () =>
            import("src/pages/accounts/common/node/NodePage.vue"),
        },
        {
          name: "service-visits",
          path: "service-visits",
          component: () =>
            import(
              "src/pages/accounts/common/node/specific/NodeServiceVisit.vue"
            ),
        },
        {
          name: "quotes",
          path: "quotes",
          component: () =>
            import("src/pages/accounts/common/node/specific/NodeQuote.vue"),
        },
        {
          name: "quote",
          path: "quotes/:id",
          component: () =>
            import("src/pages/accounts/common/node/NodePage.vue"),
        },
        {
          name: "inspections",
          path: "inspections",
          component: () =>
            import(
              "src/pages/accounts/common/node/specific/NodeInspections2.vue"
            ),
        },
        {
          name: "Route Manager",
          path: "/route-manager",
          component: () =>
            import(
              "src/pages/accounts/professional/manager/route-manager/SchedulerIndex.vue"
            ),
        },
        {
          name: "New Route Manager",
          path: "/old-route-manager",
          component: () =>
            import(
              "src/pages/accounts/professional/manager/route-manager/SchedulerEmpty.vue"
            ),
        },
        {
          name: "Route Summary",
          path: "/route-summary",
          component: () =>
            import(
              "src/pages/accounts/professional/manager/route-manager/SchedulerSummary.vue"
            ),
        },
        {
          name: "tasks",
          path: "/tasks",
          component: () =>
            import("src/pages/accounts/common/tasks/TasksTable.vue"),
        },
        {
          name: "task",
          path: "/tasks/:id",
          component: () =>
            import("src/pages/accounts/common/tasks/TaskSteps.vue"),
        },
        {
          name: "reviews",
          path: "/supervisor-reviews",

          component: () =>
            import("src/pages/accounts/common/tasks/reviews/TaskReviews.vue"),
        },
        {
          name: "customer-reviews",
          path: "/customer-reviews",

          component: () =>
            import(
              "src/pages/accounts/common/tasks/reviews/CustomerReviews.vue"
            ),
        },
        {
          name: "customer-review",
          path: "customer-reviews/:id",
          component: () =>
            import(
              "src/pages/accounts/customer/portal/servicerecords/CustomerReview.vue"
            ),
        },
        {
          name: "old-reviews",
          path: "/old-reviews",
          component: () =>
            import("src/pages/accounts/common/TasksReviewTable.vue"),
        },
        /*{
          name: "review",
          path: "/reviews/:id",
          component: () => import("src/pages/TaskReviewPage.vue"),
        },*/

        /*
        {
          path: "service-records",
          component: () => import("pages/ServiceRecords.vue"),
        },
        */
        {
          name: "timeclock",
          path: "/timeclock",
          component: () =>
            import("src/pages/accounts/common/timeclock/TimeSheetIndex.vue"),
        },
        {
          name: "mytimeclock",
          path: "/my-timeclock",
          component: () =>
            import("src/pages/accounts/common/timeclock/MyTimeClock.vue"),
        },
        {
          name: "notice-templates",
          path: "/notice-templates",
          component: () =>
            import(
              "src/pages/accounts/professional/manager/route-manager/SchedulerNoticeTemplate.vue"
            ),
        },
        {
          name: "privacy",
          path: "privacy",
          component: () => import("src/pages/legal/PrivacyPolicy.vue"),
        },
        {
          name: "terms",
          path: "terms",
          component: () => import("src/pages/legal/TermsAndConditions.vue"),
        },
        {
          name: "marketplace-pros",
          path: "/marketplace-pros",
          component: () =>
            import(
              "src/pages/accounts/professional/manager/marketplace/MarketplacePros.vue"
            ),
        },
        {
          name: "marketplace-pro",
          path: "/marketplace-pros/:pro",
          component: () =>
            import(
              "src/pages/accounts/professional/manager/marketplace/MarketplacePro.vue"
            ),
        },
        {
          name: "home",
          path: "",
          component: () =>
            import(
              "src/pages/accounts/professional/manager/dashboard/DashboardHome.vue"
            ),
        },
      ],
    },

    {
      name: "documentation",
      path: "/docs",
      component: () => import("src/layouts/DocumentationLayout.vue"),
      children: [
        {
          name: "account-term",
          path: "terms/account",
          component: () => import("src/pages/docs/terms/AccountTerm.vue"),
        },
        {
          name: "property-term",
          path: "terms/property",
          component: () => import("src/pages/docs/terms/PropertyTerm.vue"),
        },
        {
          name: "contract-term",
          path: "terms/contract",
          component: () => import("src/pages/docs/terms/ContractTerm.vue"),
        },
        {
          name: "crm-term",
          path: "terms/crm",
          component: () => import("src/pages/docs/terms/CrmTerm.vue"),
        },
        {
          name: "customer-term",
          path: "terms/customer",
          component: () => import("src/pages/docs/terms/CustomerTerm.vue"),
        },
        {
          name: "professional-term",
          path: "terms/professional",
          component: () => import("src/pages/docs/terms/ProfessionalTerm.vue"),
        },
        {
          name: "quote-term",
          path: "terms/quote",
          component: () => import("src/pages/docs/terms/QuoteTerm.vue"),
        },
        {
          name: "request-term",
          path: "terms/request",
          component: () => import("src/pages/docs/terms/RequestTerm.vue"),
        },
        {
          name: "service-record-term",
          path: "terms/service-record",
          component: () => import("src/pages/docs/terms/ServiceRecordTerm.vue"),
        },
        {
          name: "service-term",
          path: "terms/service",
          component: () => import("src/pages/docs/terms/ServiceTerm.vue"),
        },
        {
          name: "service-visit-term",
          path: "terms/service-visit",
          component: () => import("src/pages/docs/terms/ServiceVisitTerm.vue"),
        },
        {
          name: "tag-term",
          path: "terms/tag",
          component: () => import("src/pages/docs/terms/TagTerm.vue"),
        },
        {
          name: "timeclock-term",
          path: "terms/timeclock",
          component: () => import("src/pages/docs/terms/TimeclockTerm.vue"),
        },
        {
          name: "models",
          path: "models",
          component: () => import("src/pages/docs/models/ModelsPage.vue"),
        },
        {
          name: "user",
          path: "models/user",
          component: () => import("src/pages/docs/models/UserModel.vue"),
        },
        {
          name: "group",
          path: "models/group",
          component: () => import("src/pages/docs/models/GroupModel.vue"),
        },
        {
          name: "file",
          path: "models/file",
          component: () => import("src/pages/docs/models/FileModel.vue"),
        },
        {
          name: "contactModel",
          path: "models/contact",
          component: () => import("src/pages/docs/models/ContactModel.vue"),
        },
        {
          name: "objectModel",
          path: "models/object",
          component: () => import("src/pages/docs/models/JobjectModel.vue"),
        },
        {
          name: "locationModel",
          path: "models/location",
          component: () => import("src/pages/docs/models/LocationModel.vue"),
        },
        {
          name: "paymentModel",
          path: "models/payment",
          component: () => import("src/pages/docs/models/PaymentModel.vue"),
        },
        {
          name: "routableModel",
          path: "models/routable",
          component: () => import("src/pages/docs/models/RoutableModel.vue"),
        },
        {
          name: "tagModel",
          path: "models/tag",
          component: () => import("src/pages/docs/models/TagModel.vue"),
        },
        {
          name: "timeclockModel",
          path: "models/timeclock",
          component: () => import("src/pages/docs/models/TimeclockModel.vue"),
        },
        {
          name: "transactionModel",
          path: "models/transaction",
          component: () => import("src/pages/docs/models/TransactionModel.vue"),
        },
        {
          name: "nodeModel",
          path: "models/node",
          component: () => import("src/pages/docs/models/NodeModel.vue"),
        },
        {
          name: "messageModel",
          path: "models/message",
          component: () => import("src/pages/docs/models/MessageModel.vue"),
        },
        {
          name: "workflowsModel",
          path: "workflows",
          component: () => import("src/pages/docs/workflows/WorkflowsPage.vue"),
        },
        {
          name: "accounts-workflow",
          path: "accounts-workflow",
          component: () =>
            import("src/pages/docs/workflows/AccountsWorkflow.vue"),
        },
        {
          name: "new-customer-workflow",
          path: "new-customer-workflow",
          component: () =>
            import("src/pages/docs/workflows/NewCustomerWorkflow.vue"),
        },
        {
          name: "new-customer-workflow-application",
          path: "new-customer-workflow-application",
          component: () =>
            import(
              "src/pages/docs/workflows/NewCustomerWorkflowApplication.vue"
            ),
        },
        {
          name: "new-customer-workflow-initial-review",
          path: "new-customer-workflow-initial-review",
          component: () =>
            import(
              "src/pages/docs/workflows/NewCustomerWorkflowInitialReview.vue"
            ),
        },
        {
          name: "new-customer-workflow-inspection",
          path: "new-customer-workflow-inspection",
          component: () =>
            import(
              "src/pages/docs/workflows/NewCustomerWorkflowInspection.vue"
            ),
        },
        {
          name: "new-customer-workflow-quote",
          path: "new-customer-workflow-quote",
          component: () =>
            import("src/pages/docs/workflows/NewCustomerWorkflowQuote.vue"),
        },
        {
          name: "new-customer-workflow-contract",
          path: "new-customer-workflow-contract",
          component: () =>
            import("src/pages/docs/workflows/NewCustomerWorkflowContract.vue"),
        },
        {
          name: "new-professional-workflow",
          path: "new-professional-workflow",
          component: () =>
            import("src/pages/docs/workflows/NewProfessionalWorkflow.vue"),
        },
        {
          name: "new-professional-workflow-application",
          path: "new-professional-workflow-application",
          component: () =>
            import(
              "src/pages/docs/workflows/NewProfessionalWorkflowApplication.vue"
            ),
        },
        {
          name: "new-professional-workflow-initial-review",
          path: "new-professional-workflow-initial-review",
          component: () =>
            import(
              "src/pages/docs/workflows/NewProfessionalWorkflowInitialReview.vue"
            ),
        },
        {
          name: "new-professional-workflow-additional-steps",
          path: "new-professional-workflow-additional-steps",
          component: () =>
            import(
              "src/pages/docs/workflows/NewProfessionalWorkflowAdditionalSteps.vue"
            ),
        },
        {
          name: "servservice-visiticing-workflow",
          path: "service-visit-workflow",
          component: () =>
            import("src/pages/docs/workflows/ServiceVisitWorkflow.vue"),
        },
        {
          name: "customer-payment-workflow",
          path: "customer-payment-workflow",
          component: () =>
            import("src/pages/docs/workflows/CustomerPaymentWorkflow.vue"),
        },
        {
          name: "professional-payment-workflow",
          path: "professional-payment-workflow",
          component: () =>
            import("src/pages/docs/workflows/ProfessionalPaymentWorkflow.vue"),
        },
        {
          name: "time-tracking",
          path: "time-tracking",
          component: () => import("src/pages/docs/workflows/TimeTracking.vue"),
        },
        {
          name: "requests",
          path: "requests",
          component: () => import("src/pages/docs/workflows/RequestsPage.vue"),
        },
        {
          name: "processes",
          path: "processes",
          component: () => import("src/pages/docs/processes/ProcessesPage.vue"),
        },
        {
          name: "tree",
          path: "tree",
          component: () => import("src/pages/docs/TreePage.vue"),
        },
      ],
    },
    {
      name: "catchAll",
      path: "/:catchAll(.*)*",
      component: () => import("pages/accounts/common/auth/ErrorNotFound.vue"),
    },
  ],
  crew: [
    {
      name: "crew",
      path: "/",
      component: () => import("src/layouts/CrewLayout.vue"),
      children: [
        /*{
          name: "index",
          path: "",
          component: () => import("src/pages/accounts/professional/crew/Index.vue"),
        },*/
        {
          name: "home",
          path: "/home",
          component: () => import("src/pages/home/home/HomePage.vue"),
        },
        {
          name: "weather",
          path: "/weather",
          meta: {},
          component: () => import("src/pages/home/weather/MyWeather.vue"),
        },
        {
          name: "timeclocks",
          path: "/timeclocks",
          component: () =>
            import("src/pages/accounts/common/timeclock/TimeclockPage.vue"),
        },
        {
          name: "timeclock",
          path: "/timeclock",
          component: () =>
            import("src/pages/accounts/common/timeclock/MyTimeClock.vue"),
        },
        {
          name: "learn",
          path: "/learn",
          component: () => import("src/pages/help/learn/LearnMenuPage.vue"),
        },
        {
          name: "learn-video",
          path: "/learn/:id",
          component: () => import("src/pages/help/learn/LearnVideoPage.vue"),
        },
        {
          name: "mytimeclock1",
          path: "",
          component: () =>
            import("src/pages/accounts/common/timeclock/MyTimeClock.vue"),
        },

        {
          name: "timeclocks",
          path: "/timeclocks",
          component: () =>
            import("src/pages/accounts/common/timeclock/TimeclockPage.vue"),
        },
        /*
        {
          name: "preferences",
          path: "/preferences",
          component: () => import("src/pages/accounts/common/preferences/PreferencesPage.vue"),
        },
        */
        /*
        {
          name: "confirm",
          path: "confirm",
          component: () => import("src/pages/ConfirmTimeclock.vue"),
        },
        */
        {
          name: "requests",
          path: "requests",
          component: () =>
            import("src/pages/accounts/common/requests/RequestsList.vue"),
        },
        {
          name: "request",
          path: "requests/:id",
          component: () =>
            import("src/pages/accounts/common/requests/RequestActionPage.vue"),
        },
        {
          name: "change-password",
          path: "/change-password",
          component: () =>
            import("src/pages/accounts/common/auth/ChangePassword.vue"),
        },
        {
          path: "/profile",
          name: "profile",
          component: () =>
            import("src/pages/accounts/professional/crew/CrewProfile.vue"),
        },
        {
          name: "tasks",
          path: "/tasks",
          component: () =>
            import("src/pages/accounts/common/tasks/TasksTable.vue"),
        },
        {
          name: "task",
          path: "/tasks/:id",
          component: () =>
            import("src/pages/accounts/common/tasks/TaskSteps.vue"),
        },
        {
          name: "customer-reviews",
          path: "/customer-reviews",
          component: () =>
            import(
              "src/pages/accounts/common/tasks/reviews/CustomerReviews.vue"
            ),
        },
        {
          name: "customer-review",
          path: "customer-reviews/:id",
          component: () =>
            import(
              "src/pages/accounts/customer/portal/servicerecords/CustomerReview.vue"
            ),
        },
        {
          name: "privacy",
          path: "privacy",
          component: () => import("src/pages/legal/PrivacyPolicy.vue"),
        },
        {
          name: "terms",
          path: "terms",
          component: () => import("src/pages/legal/TermsAndConditions.vue"),
        },
      ],
    },
    {
      name: "catchAll",
      path: "/:catchAll(.*)*",
      component: () => import("pages/accounts/common/auth/ErrorNotFound.vue"),
    },
  ],
  provider: [
    {
      name: "provider",
      path: "/",
      component: () => import("src/layouts/ProviderLayout.vue"),
      children: [
        {
          name: "index",
          path: "",
          component: () =>
            import(
              "src/pages/accounts/professional/provider/ProviderIndex.vue"
            ),
        },
        {
          name: "application",
          path: "application",
          component: () =>
            import(
              "src/pages/accounts/professional/provider/ProviderApplication2.vue"
            ),
        },
        {
          name: "provider-send-invoices-test",
          path: "provider-send-invoices-test",
          component: () =>
            import(
              "src/pages/accounts/professional/provider/SendInvoicesTest.vue"
            ),
        },
        {
          name: "provider-payments-test",
          path: "provider-payments-test",
          component: () =>
            import(
              "src/pages/accounts/professional/provider/application/StripeAccount.vue"
            ),
        },
        {
          name: "weather",
          path: "/weather",
          meta: {},
          component: () =>
            import("src/pages/home/weather/weather/MyWeather.vue"),
        },
        {
          name: "offers",
          path: "offers",
          component: () =>
            import(
              "src/pages/accounts/professional/provider/portal/OffersPage.vue"
            ),
        },
        {
          name: "properties",
          path: "properties",
          component: () =>
            import(
              "src/pages/accounts/professional/provider/portal/PropertiesPage.vue"
            ),
        },
        {
          name: "inspections2",
          path: "inspections2",
          component: () =>
            import(
              "src/pages/accounts/professional/provider/portal/InspectionsPage.vue"
            ),
        },
        {
          name: "inspections",
          path: "inspections",
          component: () =>
            import(
              "src/pages/accounts/professional/provider/InspectionsPage.vue"
            ),
        },
        {
          name: "inspection",
          path: "inspections/:id",
          component: () =>
            import(
              "src/pages/accounts/professional/provider/InspectionPage.vue"
            ),
        },
        {
          name: "services",
          path: "services",
          component: () =>
            import(
              "src/pages/accounts/professional/provider/portal/ServicesPage.vue"
            ),
        },
        {
          name: "service",
          path: "services/:id",
          component: () =>
            import(
              "src/pages/accounts/professional/provider/portal/ServicePage.vue"
            ),
        },
        {
          name: "tasks",
          path: "/tasks",
          component: () =>
            import("src/pages/accounts/common/tasks/TasksTable.vue"),
        },
        {
          name: "task",
          path: "/tasks/:id",
          component: () =>
            import("src/pages/accounts/common/tasks/TaskSteps.vue"),
        },
        {
          name: "billing",
          path: "billing",
          component: () =>
            import("src/pages/accounts/customer/portal/BillingPage.vue"),
        },
        {
          name: "invoices",
          path: "invoices",
          component: () =>
            import(
              "src/pages/accounts/professional/provider/portal/InvoicesPage.vue"
            ),
        },
        {
          name: "service-records",
          path: "service-records",
          component: () =>
            import(
              "src/pages/accounts/professional/provider/portal/ServiceRecords2.vue"
            ),
        },
        {
          name: "service-record",
          path: "service-records/:id",
          component: () =>
            import(
              "src/pages/accounts/professional/provider/portal/sr/CustomerReview.vue"
            ),
        },
        {
          name: "messages",
          path: "messages",
          component: () =>
            import(
              "src/pages/accounts/professional/provider/portal/MessagesPage.vue"
            ),
        },
        /*{
          name: "inspection",
          path: "inspections/:id",
          component: () => import("src/pages/accounts/professional/provider/InspectionPage.vue"),
        },*/

        {
          name: "profile",
          path: "profile",
          component: () =>
            import(
              "src/pages/accounts/professional/provider/ProviderProfile.vue"
            ),
        },
        {
          name: "change-password",
          path: "change-password",
          component: () =>
            import("src/pages/accounts/common/auth/ChangePassword.vue"),
        },
        {
          name: "privacy",
          path: "privacy",
          component: () => import("src/pages/legal/PrivacyPolicy.vue"),
        },
        {
          name: "terms",
          path: "terms",
          component: () => import("src/pages/legal/TermsAndConditions.vue"),
        },
        {
          name: "user",
          path: "user",
          component: () => import("src/components/portal/User.vue"),
        },
        {
          name: "security",
          path: "security",
          component: () => import("src/components/portal/Security.vue"),
        },
        {
          name: "profile",
          path: "/profile",
          component: () =>
            import("src/pages/accounts/professional/crew/CrewProfile.vue"),
        },
        {
          name: "preferences",
          path: "/preferences",
          component: () =>
            import("src/pages/accounts/customer/portal/UserPreferences.vue"),
        },
      ],
    },
  ],
  contractor: [
    {
      name: "contractor",
      path: "/",
      component: () => import("src/layouts/NewProLayout.vue"),
      children: [
        {
          name: "requests",
          path: "requests",
          component: () =>
            import("src/pages/accounts/common/requests/RequestsList.vue"),
        },
        {
          name: "request",
          path: "requests/:id",
          component: () =>
            import("src/pages/accounts/common/requests/RequestActionPage.vue"),
        },
        {
          name: "index",
          path: "",
          component: () =>
            import("src/pages/accounts/professional/common/ProIndex.vue"),
        },
        {
          name: "application",
          path: "application",
          component: () =>
            import(
              "src/pages/accounts/professional/registration/ProRegistration.vue"
            ),
        },
        {
          name: "profile",
          path: "profile",
          component: () =>
            import("src/pages/accounts/professional/crew/CrewProfile.vue"),
        },
        /*{
          name: "profile",
          path: "profile",
          component: () => import("src/components/portal/CrewProfile.vue"),
        },*/
        {
          name: "change-password",
          path: "change-password",
          component: () =>
            import("src/pages/accounts/common/auth/ChangePassword.vue"),
        },
        {
          name: "privacy",
          path: "privacy",
          component: () => import("src/pages/legal/PrivacyPolicy.vue"),
        },
        {
          name: "terms",
          path: "terms",
          component: () => import("src/pages/legal/TermsAndConditions.vue"),
        },
      ],
    },
    {
      name: "catchAll",
      path: "/:catchAll(.*)*",
      component: () => import("pages/accounts/common/auth/ErrorNotFound.vue"),
    },
  ],
};

export default routes;
